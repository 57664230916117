import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import "../components/layout.scss"
import GithubSocial from "../images/github.svg"
import LinkedinSocial from "../images/linkedin.svg"
import InstagramSocial from "../images/instagram.svg"

const IndexPage = () => (
  <div id="interWrap" style={{
    display: `flex`,
    flexDirection: `column`,
    minHeight: `100vh`
  }}>
    <SEO title="Eric Sutphen / Software Developer" />
    <div className="IndexHero">
      <div style={{
        marginBottom: `3rem`
      }}>
        <h1 style={{marginBottom: '1.45rem'}}>
          I'm <strong>Eric Sutphen</strong>,<br/>
          a software developer 
          <span style={{
            display: `inline-block`
          }}>currently in Yonkers, NY.</span>
        </h1>
        <div>
          <ul className="SocialIcons">
            <li className="SocialIcons-icon"><Link to="https://www.instagram.com/estuffin/" target="_blank"><InstagramSocial/></Link></li>
            <li className="SocialIcons-icon"><Link to="https://github.com/estuffin" target="_blank"><GithubSocial/></Link></li>
            <li className="SocialIcons-icon"><Link to="https://www.linkedin.com/in/ericsutphen/" target="_blank"><LinkedinSocial/></Link></li>
          </ul>
        </div>
      </div>
      <nav style={{
        display: `flex`,
        alignItems: `center`,
        position: `relative`,
        padding: 0,
        width: `100%`
      }}>
        <ul id="IndexNav">
          <li>
            <Link to="/about/">About</Link>
          </li>
        </ul>
      </nav>
    </div>
    <div id="g" style={{height:`1360px`}}></div>
  </div>
)

export default IndexPage
